.main_header_css {
  padding: 5px 15px;
  background-color: #1470af;
  display: flex;
  height: 90px;
  justify-content: center;
  align-items: center;
}
.main_heading_css {
  font-family: "Fraunces", serif;
  font-size: 22px !important;
  font-weight: 700 !important;
  color: white !important;
  line-height: 24px !important;
}
.item_heading_css {
  font-family: Lato !important;
  font-size: 18px !important;
  font-weight: 500 !important;
  color: white !important;
  line-height: 24px !important;
}

.button_login_css {
  font-family: Lato !important;
  font-size: 16px !important;
  font-weight: 400 !important;
  color: rgb(12, 11, 11);
  line-height: 24px !important;
  text-align: left !important;
  text-decoration: none !important;
  text-transform: inherit !important;
  cursor: pointer;
  padding: 2px;
  width: 90px;
  border-radius: 8px;
  background-color: white;
}

.second_grid_css {
  display: flex;
  gap: 20px;
  align-items: center;
  /* flex-wrap: wrap !important; */
  width: 100%;
  justify-content: end;
}
.logo_css {
  width: 100px;
  background: #f0f8fff0;
  box-shadow: 0 4px 8px 0 rgb(0 0 0), 0 6px 20px 0 rgb(0 0 0 / 58%);
  border-radius: 50%;
}
.footer_item_box_css {
  display: flex;
  flex-wrap: wrap;
  justify-content: start;
  gap: 30px;
  align-items: center;
}
.header_item_box_css {
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
  justify-content: center;
  align-items: center;
}
.footer_item_second_box_item {
  gap: 20px;
  display: flex;
  justify-content: flex-end;
}
.logo_style {
  object-fit: contain;
  width: 35px;
  height: 35px;
  cursor: pointer !important;
}
.drawerparent {
  margin-top: 3rem;
  padding: 5px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  font-family: Lato;
}

.drawer {
  position: absolute;
  top: 25px;
  right: 20px;
}
.drawerbtn {
  font-family: Lato !important;
  font-size: 16px !important;
  font-weight: 400 !important;
  color: rgb(12, 11, 11);
  line-height: 24px !important;
  text-align: left !important;
  text-decoration: none !important;
  text-transform: inherit !important;
  cursor: pointer;
  padding: 2px;
  width: 100%;
  border-radius: 6px;
  background-color: rgb(209, 209, 209) !important;
}
/*
media query
*/
@media (max-width: 899px) {
  .main_header_css {
    padding: 5px 10px;
    gap: 10px;
    background-color: #1470af;
    display: flex;
    justify-content: center;
    height: unset;
    align-items: center;
  }
  .header_item_box_css {
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
    justify-content: center;
    align-items: center;
  }
}
@media (max-width: 430px) {
  .main_heading_css {
    font-size: 20px !important;
    font-weight: 650 !important;
  }
}

@media (max-width: 430px) {
  .item_heading_css {
    display: none;
  }
}

@media (max-width: 430px) {
  .footer_item_second_box_item {
    display: flex;
    justify-content: center;
  }
}

@media (max-width: 50px) {
  .header_item_box_css {
    display: none !important;
  }
  .hidebutton {
    display: none !important;
  }
}
@media (min-width: 431px) {
  .drawer {
    display: none !important;
  }
}
