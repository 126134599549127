.main{
    min-height: calc(100vh - 180px);
}
@media(max-width:900px){
    .main{
        min-height: unset
    }
}

.heading-container{
    padding-top: 50px;
    display :flex;
    flex-direction: column;
    gap:20px;
    margin-left: 30px;
}

.divider{
    width: 95%;
}
.filter-container{
    margin-top: 10px;
    margin-left: 30px;
    display: flex;
    gap: 30px;
}

@media(max-width:400px){
    .filter-container div button{
       
        margin-left: 0px;
    
    }
}
.calander-container{
  display: flex;
  margin-top: 10px;
  padding: 10px;
}
.calander{
    padding: 5px;
}

:focus-visible {
    outline: none;
}

.button1 {
    font-family: Lato !important;
    font-size: 16px !important;
    font-weight: 600 !important;
    line-height: 24px !important;
    text-align: left !important;
    text-decoration: none !important;
    text-transform: inherit !important;
    cursor: pointer;
    padding: 4px;
    color: white !important;
    width: 170px;
    height: 50px;
    border-radius: 30px !important;
    background-color: #050A44  !important;
    margin: 5px 5px; 
    position: relative;
    right:7rem;
    bottom: 5rem;
  }

  @media (max-width: 1460px) {
    .button1 {
        position: relative;
        right:7rem;
        bottom: 5rem;
    }
  }
  @media (max-width: 1000px) {
    .button1 {
        margin: 0 auto !important;

        position: relative !important;
        right: -8px;
        width: 200px;
        height: 50px;
        bottom: 20px;
    }
  }  