
.pricing_plan_typography_css{
    font-family: Inter !important;
  font-size: 20px !important;
  font-weight: 700 !important; 
  line-height: 26px !important;
  
  }
  .pricing_plan_typography_label{
    font-family: Inter !important;
  font-size: 15px !important;
  font-weight: 500 !important; 
  line-height: 26px !important;
  
  }
  .pricing_plan_typography_second_heading{
    font-family: Inter !important;
  font-size: 16px !important;
  font-weight: 700 !important; 
  line-height: 26px !important;
  
  }
  .pricing_plan_typography_btn{
    font-family: Inter !important;
  font-size: 18px !important;
  font-weight: 500 !important; 
  line-height: 26px !important;
  max-width: 300px !important;
  min-width: 300px !important;
  width: 100% !important;
  padding:10px !important;
  height: 44px !important;
  border-radius:8px !important;
  text-transform: inherit !important;
  background-color: #050A44 !important;
  
  }
  .pricing_plan_price_css{
    font-family: Inter !important;
  font-size: 53px !important;
  padding:10px;
  font-weight: 700 !important; 
  line-height: 26px !important;
  
  }
  .border_color{
    height: 1px !important;
    width: 300px !important;
    background: #050A44 !important;
  }
  .subHeadingValid_css { 
    display: flex !important;
    gap: 18px !important;
      justify-content: center !important;
      align-items: center !important;
      font-family: Inter !important;
  font-size: 18px !important;
  font-weight: 500 !important; 
  line-height: 26px !important;
  
  }